import React from "react"
import styled from "@emotion/styled"
import articles from "../articles-db"

const Blog = () => {
  const article = articles.find(art => art.id === "1")

  return (
    <ArticleSection>
      <ArticleContainer>
        <ArticleTextContainer>
          <ArticleTitle>{article.title}</ArticleTitle>
          <ArticleDate>Publié le {article.date}</ArticleDate>

          <ArticleImageContainer>
            <img
              src={article.image}
              alt={article.title}
              className="articleImage"
            ></img>
          </ArticleImageContainer>
          <ArticleIntro>{article.intro}</ArticleIntro>
          <ArticleSubTible>Les origines chinoises du ramen</ArticleSubTible>
          <ArticleText>
            Plusieurs légendes existent quant à l’origine du ramen mais toutes
            s’accordent à dire que cette soupe est héritée de la tradition
            chinoise. Selon le musée du ramen à Yokohama, ça serait dans cette
            ville japonaise que le ramen fit sa première apparition, en 1859 en
            raison de son grand port et donc de l’arrivée des migrants chinois.
            Il s’agissait à l’époque d’un bouillon de nouilles chinoises,
            accompagné de rôtis de porc, de pousses de bambou et d’un demi œuf
            dur. Cette soupe était très populaire car économique et
            nourrissante.
            <br></br>
            <br></br>
            Le ramen se serait ensuite démocratisé au XXe siècle dans d’autres
            villes japonaises et les habitants du pays du soleil levant s’en
            s’ont tellement emparés qu’il est désormais considéré comme un plat
            typiquement japonais. En effet, au fur et à mesure du temps, cette
            soupe chinoise a connu tellement de variations, qu’elle est
            désormais assez éloignée du ramen si populaire que nous connaissons.
          </ArticleText>
          <ArticleSubTible>La définition du ramen japonais</ArticleSubTible>
          <ArticleText>
            Encore aujourd’hui, il existe plus d’une dizaine de recettes
            différentes qui varient selon les régions. Tout d’abord le bouillon
            peut être riche, il sera appelé kotteri ; ou alors ssari,
            c’est-à-dire léger. Ce bouillon est assaisonné de différentes façons
            : au miso ou à la sauce soja (shoyu) comme chez Hara-Kiri, mais il
            peut aussi être assaisonné simplement au sel (shio) ou aux os de
            porc (tokontsu). Ensuite ce bouillon est garni par de multiples
            ingrédients : des tranches de chashu de porc ou de poulet, des
            oeufs, du chou, des algues…
            <br></br>
            <br></br>
            Mais ce sont les nouilles qui donnent au ramen son nom mais aussi
            son identité. Les fameux « ramens » sont en effet ces nouilles fines
            qu’on différencie des nouilles plus épaisses appelées Udon. Elles
            sont faites à base de farine de blé, d’eau, de sel et d’eau alcaline
            (kansui). Leur particularité est qu’elles sont travaillées et
            étirées à la main.{" "}
          </ArticleText>
          <ArticleSubTible>La naissance d’une culture du ramen</ArticleSubTible>
          <ArticleText>
            Désormais, ces traditionnelles soupes chinoises ont donné naissance
            à une véritable culture du ramen se développant au delà des
            frontières. Chaque chef, pour se démarquer, tente alors d’apporter
            un peu d’originalité et de rendre la recette de son ramen unique.
            C’est le cas chez Hara-kiri, où les bouillons ont été réfléchi par
            notre chef avec le plus grand soin pour offrir à nos visiteurs un
            billet direct pour le Japon. Et pour les amateurs d’aventure,
            Hara-Kiri vous propose d’expérimenter son ramen signature à la
            truffe noire.
          </ArticleText>
        </ArticleTextContainer>
      </ArticleContainer>
    </ArticleSection>
  )
}

const ArticleSection = styled.section`
  margin-top: 100px;
  padding: 0 10%;
`

const ArticleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;

  @media screen and (max-width: 640px) {
    flex-wrap: wrap;
  }
`

const ArticleTextContainer = styled.div`
  width: 80%;
  /* padding: 20px 60px; */
  margin: auto;

  @media screen and (max-width: 640px) {
    width: 100%;
    padding: 20px;
  }
`

const ArticleTitle = styled.p`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 4px;
`
const ArticleDate = styled.p`
  margin-bottom: 40px;
  font-size: 14px;
`
const ArticleIntro = styled.p`
  font-size: 18px;
  margin-bottom: 40px;
`

const ArticleSubTible = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`

const ArticleText = styled.p`
  font-size: 14px;
  margin-bottom: 40px;
`

const ArticleImageContainer = styled.div`
  width: 100%;
  height: 400px;
  background-color: #fff;
  margin-bottom: 50px;

  .articleImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 640px) {
    width: 80vw;
    height: 80vw;
  }
`

export default Blog
