import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Article1 from "../components/Article1"

const IndexPage = () => (
  <Layout headerColor={"black"}>
    <SEO title="Qu'est ce qu'un ramen ?" />
    <Article1 />
  </Layout>
)

export default IndexPage
